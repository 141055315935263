$custom-colors: (
  "red": #f44336,
  "pink": #e91e63,
  "purple": #9c27b0,
  "indigo": #3f51b5,
  "blue": #2196f3,
  "cyan": #00bcd4,
  "teal": #009688,
  "green": #4caf50,
  "yellow": #ffe821,
  "orange": #ff9800,
  "deep-orange": #ff5722,
  "brown": #795548,
  "grey": #9e9e9e,
  "black": #000000,
  "white": #ffffff,
  "amber": #ffc107,
) !default;

$linear-colors: (
  "green": linear-gradient(135deg, #23bdb8 0%, #43e794 100%),
  "green-dark": linear-gradient(135deg, #23bdb8 0, #65a986 100%),
  "orange": linear-gradient(135deg, #f48665 0%, #fda23f 100%),
  "orange-dark": linear-gradient(135deg, #f48665 0, #d68e41 100%),
  "cyan": linear-gradient(135deg, #289cf5, #84c0ec),
  "cyan-dark": linear-gradient(135deg, #289cf5, #4f8bb7),
  "red": linear-gradient(to right, #a77ffc 0%, #ff6eac 100%),
  "purple": linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%),
  "purple-dark": linear-gradient(135deg, #8e4cf1 0, #c554bc 100%),
  "yellow": linear-gradient(to right, #f6e384, #ffd500),
  "card1": linear-gradient(to left, #3a7bd5, #3a6073),
  "card2": linear-gradient(to right, #c33764, #1d2671),
  "card3": linear-gradient(to left, #134e5e, #71b280),
  "card4": linear-gradient(to left, #d38312, #a83279),
);

//Fonts Family
$navbar-font-family: "Roboto", sans-serif;
$sidebar-font-family: "Roboto", sans-serif;
